import React, { useState, useMemo, useEffect, useRef } from 'react';
import Pagination from './Pagination';
import './Style.css';
import axios from 'axios';
import arrow from './icons/arrows-up-down.png';
import emailIcon from './icons/email.png';
import Popup from './Popup';


const PageSize = 10;

export default function AddTable({ searchQuery, selectedFromDate, selectedToDate, selectedFilters, onChangeData }) {

  console.log(selectedFilters, 'table')
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchedData, setFetchedData] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [totalPageCount, setTotalPageCount] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  // Add a new state to store the original data
  const [originalData, setOriginalData] = useState([]);
  const [changedData, setChangedData] = useState([]);
  const snackBar = useRef();
  const [showModal, setShowmodal] = useState(false);
  const [selectedID, setSelectedID] = useState();




  // useEffect(() => {
  //   // Sort the initial data by name
  //   const initialData = [...mockData];
  //   initialData.sort((a, b) => a.name.localeCompare(b.name));
  //   setFetchedData(initialData);
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true); // Set loading state to true when starting to fetch data

        const response = await axios.post('https://varapi.vthinkglobal.com/api/report', {
          fromDate: selectedFromDate,
          toDate: selectedToDate

        }, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const apiData = response.data;

        // Sort the data by name
        apiData?.report.sort((a, b) => a.name.localeCompare(b.name));

        // Set your state or perform other actions with the data
        setFetchedData(apiData.report);
        setOriginalData(apiData.report)
        setIsLoading(false); // Set loading state to false when data is fetched
      } catch (error) {
        console.error('Error fetching data from the API', error);
        setIsLoading(false); // Set loading state to false in case of an error
      }
    };

    fetchData();
  }, [selectedFromDate, selectedToDate]);

  const handleSortByName = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);

    // Sort the data by name
    const sortedData = [...fetchedData];
    sortedData.sort((a, b) => {
      return newSortOrder === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
    });

    setFetchedData(sortedData);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    // Filter the data based on the search query
    const filteredData = fetchedData.filter((item) =>
      (item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (item.project && item.project.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (item.met && item.met.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    if (selectedFilters && Object.keys(selectedFilters).length > 0) {
      // Apply additional filters based on selectedFilters
      let filteredByCheckbox = fetchedData.filter((item) => {
        return (
          (selectedFilters[item.project]) ||
          (selectedFilters[item.shift]) ||
          ((selectedFilters.lessThan9Hours && item.is_nine_met < 100) ||
            (selectedFilters.greaterThan9Hours && item.is_nine_met >= 100)) ||
          ((selectedFilters.onTime && item.is_on_time >= 100.00) ||
            (selectedFilters.late && item.is_on_time < 100)) ||
          (selectedFilters[item.lead])
        );
      });

      if (filteredByCheckbox.length === 0) {
        filteredByCheckbox = originalData;
      }

      setChangedData(filteredByCheckbox); // Update changedData state

      setTotalPageCount(filteredByCheckbox.length);

      // Call the callback function to send data to Datepicker.js
      onChangeData(filteredByCheckbox);

      return filteredByCheckbox.slice(firstPageIndex, lastPageIndex);
    }

    setChangedData(filteredData);
    setTotalPageCount(filteredData.length);

    // Call the callback function to send data to Datepicker.js
    onChangeData(filteredData);

    return filteredData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, searchQuery, fetchedData, selectedFilters]);

  const formatTime = (timeString) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    const time = new Date(`2000-01-01 ${timeString}`);
    return time.toLocaleTimeString('en-US', options);
  };

  const sendMail = async (empId) => {
    console.log(empId);
    try {

      console.log("CAMEE", empId, typeof (empId));
      const response = await axios.post('https://varapi.vthinkglobal.com/api/common/send-mail', {
        employeeId: empId
      },{
        headers: {
          'Content-Type': 'application/json',
        },
      }
      );
      setShowmodal(false);
      snackBar.current.className = "show";
    } catch (err) {
      console.log(err)
    } finally {
      setTimeout(() => {
        snackBar.current.className = "";
      }, 3000);
    }
  }




  // console.log("currentTableData",currentTableData)
  // console.log(changedData,"changedData")


  return (
    <>
      <div style={{ paddingLeft: "90px" }}>
        {isLoading ? ( // Conditionally render loader
          <img src='https://media.tenor.com/JBgYqrobdxsAAAAi/loading.gif' style={{ width: '90px', paddingTop: '10%' }}></img>
        ) : (
          <>
            {currentTableData.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>Emp Id</th>
                    <th onClick={handleSortByName}>Name {sortOrder === 'asc' ? <img src={arrow} alt="Ascending" /> : <img src={arrow} alt="Descending" />}</th>
                    <th>Project</th>
                    <th>Lead</th>
                    <th>Shift</th>
                    <th>WFH</th>
                    <th>Leaves</th>
                    <th>Average Hours(FRT)</th>
                    <th>Productive Hours(TCR)</th>
                    <th>9 Met?</th>
                    <th>On_Time</th>
                    <th>Invalid Pairs</th>
                    <th>LOP</th>
                    <th>UnApplied</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentTableData.map(item => {
                    return (
                      <tr key={item.employee_id}>
                        <td>{item.employee_id}</td>
                        <td>{item.name}</td>
                        <td>{item.project || '-'}</td>
                        <td>{item.lead || '-'}</td>
                        <td>{formatTime(item.shift) || '-'}</td>
                        <td>{item.wfh}</td>
                        <td>{item.leave}</td>
                        <td>{item.average_hours || '-'}</td>
                        <td>{item.productive_hours || '-'}</td>
                        <td>{item.is_nine_met}</td>
                        <td>{item.is_on_time}</td>
                        <td>{item.invalid_pair}</td>
                        <td>{item.lop}</td>
                        <td>{item.unapplied_leave}</td>
                        <td>{
                          <button className='mail-btn' onClick={() => {
                            setShowmodal(true);
                            setSelectedID(item.employee_id);
                          }}>
                            <img src={emailIcon}></img>
                            Email
                          </button>
                        }</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <p style={{ paddingTop: "10%" }}>No records found!</p>
            )}
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={totalPageCount}
              pageSize={PageSize}
              onPageChange={page => setCurrentPage(page)}
            />
          </>
        )}
      </div>
      {
        showModal ?
          (
            <div id="modalComponent">
              <div className='modalContainer'>
                <p>Are you sure you want to send mail?.</p>
                <div className='action_button'>
                  <button className='mail-btn' onClick={() => {
                    setShowmodal(false);
                    setSelectedID("");
                  }}>No</button>
                  <button className='mail-btn' onClick={() => {
                    sendMail(selectedID) }}>Yes</button>
                </div>
              </div>
            </div>
          ) : ""
      }
      <div ref={snackBar} id="snackbar">Mail Sent Successfully!!</div>
    </>
  )
};